/* poppins-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../public/fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../public/fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../public/fonts/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/fonts/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
} 

/* poppins-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('../public/fonts/poppins-v20-latin-italic.eot'); /* IE9 Compat Modes */
  src: url('../public/fonts/poppins-v20-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/poppins-v20-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/poppins-v20-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../public/fonts/poppins-v20-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/fonts/poppins-v20-latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../public/fonts/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: url('../public/fonts/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/poppins-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/poppins-v20-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../public/fonts/poppins-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/fonts/poppins-v20-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../public/fonts/poppins-v20-latin-600.eot'); /* IE9 Compat Modes */
  src: url('../public/fonts/poppins-v20-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/poppins-v20-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/poppins-v20-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../public/fonts/poppins-v20-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/fonts/poppins-v20-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../public/fonts/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: url('../public/fonts/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/poppins-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/poppins-v20-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../public/fonts/poppins-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/fonts/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('../public/fonts/poppins-v20-latin-800.eot'); /* IE9 Compat Modes */
  src: url('../public/fonts/poppins-v20-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/fonts/poppins-v20-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/poppins-v20-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../public/fonts/poppins-v20-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/fonts/poppins-v20-latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
}


* {    font-family: 'Poppins', sans-serif; }

:root { 
  --dark-bg: #1b1c30;
  --card-bg-color: #262740;
  --dark-accent: #0c1221;
  --bright-text: #b3b5c3;
  --bright-accent: #b3b5c3;
  --darker-text: #5b5c6f;
  --bright-bg: #eaf3ff;
  --white: #fff;
  --purple: #5a5ead;
  --purple-2: #5449a4;
  --true-bg:#1a1d37;

}

:root { 
  --dark-bg: #1b1c30;
  --card-bg-color: #262740;
  --dark-accent: #0c1221;
  --bright-text: #b3b5c3;
  --bright-accent: #b3b5c3;
  --darker-text: #5b5c6f;
  --bright-bg: #eaf3ff;
  --white: #fff;
  --purple: #5a5ead;
  --purple-2: #5449a4;
  --true-bg:#fcfcfc;
  --side-bg: #14171f;
}



body {
  color: var(--bright-text);
  background-color: var(--true-bg);
}

.logo { 
}
.logo a {
  padding:10px;
  font-size:1em;
  color:#000;
  text-decoration: none;;
  display:inline-block;
  margin:auto;
}
.authed .logo a {
  text-align:center;
  color:#fff;
}
.authed .logo {
  text-align:center;
}
a.green svg {
  color:#16ac78;
}
.login-page input.textbox {
  background-color: rgba(255,255,255,0.1);
  border-radius: 5px;
  box-sizing: border-box;
  font-family: Lato,sans-serif;
  font-size: 1em;
  height: 40px;
  margin: 5px 0;
  padding: 7px 20px;
  width: 100%;
}
.login-page button.btn-submit, button.default {
  background-color: #252525;
  background-color: var(--main-color);
  border: none;
  box-sizing: border-box;
  color: #fff;
  font-family: Lato,sans-serif;
  font-size: 1em;
}

input.login {
  color:#fff;
  border: none;
  box-sizing: border-box;
  outline: none;
}

* {
  font-family: Poppins,sans-serif;
  transition: border-color .3s;
}

input.login:focus {
  border: none;
  border-top: 2px solid var(--purple);
}

.login-page { 
  padding: 20px 40px;
  background-color:var(--card-bg-color);
}
.login-page button.btn-submit, button.default {
  background-color: var(--purple);
  box-sizing: border-box;
  color: #fff;
  font-family: Lato,sans-serif;
  font-size: 1em;
}

.login-page button.btn-submit {
  margin-top: 30px;
  margin-bottom:10px;
  padding: 7px 20px;
  font-weight:bold;
  width: 100%;
  transition: .5s;
}
.login-page button.btn-submit:hover {
  background-color: var(--purple-2);
}

button {
  cursor:pointer;
}

.login-page {
  margin: 100px auto auto;
  width: 440px;
}
@media only screen and (max-width: 767px) {
  .login-page {
    margin: 100px auto auto;
    width: 90%;
  }
}
div {
  box-sizing: border-box;
}

.login-page h4 {
  text-align:center;
  margin-top:5px;
}

html, body, #root,#wrap { height: 100%;height: 100vh; }

.authed .left-bar {
background-color:var(--side-bg);
height:100%;
width:200px;
min-width:200px;
border-right: 1px solid var(--card-bg-color);
}
.authed { 
  display:flex;
  flex-direction: row;
  height:100%;
}
.authed .main {
  min-width:760px;
  overflow-y: auto;
  flex: 7 0;
}

@media only screen and (max-width: 767px) {
  .authed .left-bar {
    display:none;
    }
    .authed .left-bar.toggled {
      height:100vh;
      width:80%;
      display:block !important;
      min-width:150px;
      max-width:380px;
      position:absolute; left:0; top:0;
      z-index:1000;
    }
    .authed .main {
      min-width:unset;
    }
}


.menu ul {
  list-style-type:none;
  padding:0px;
}
.menu ul li a svg {
  width:20px;
  margin-right:10px;
  border-right:2px solid rgba(255,255,255,0.3);
  padding-right:10px;

}
.menu ul li.active a { 
  color:#fff;
  border-left:4px solid var(--bright-text);
  padding-left:11px;
  background-color:rgba(0,0,0,0.3);
}
.menu ul li a {
  text-decoration: none;
  color:unset;
  cursor:pointer;
  padding: 10px;
  padding-left:15px;
  margin-bottom:1px;
  border-radius:0px;
  position:relative;
  font-size:.9em;
  display:block;
}

.menu ul li.active svg {
}


.menu ul li a:hover {
  color:#fff;
  background-color:rgba(0,0,0,0.3);
}
.menu ul li .submenu  {
  margin:5px;
  background-color:rgba(255,255,255,0.3);
}
.menu ul li.minor {
} 
.menu ul li.menu-title {
  padding: 0px 10px 10px 10px;
  font-size:.8em;
  color:#fff;
}
.menu ul .dropdown { 
  background-color:rgba(0,0,0,.2);;
  border-radius:5px;
  overflow:hidden;
  margin: 0px 10px 10px 10px;
}
.menu ul li.minor a {
  padding:5px;
  padding-left:30px;
  
} 
.menu .bottom {
  padding-top:15px;
  border-top: 1px solid rgba(255,255,255,0.1);
}

.wrap-content { 
  margin:auto;
  margin-top:40px;
  margin:auto;
  padding:20px;
}
.service-select .img {
  margin-right:20px;
  background-position: center center;
  background-size:50%;
  background-repeat: no-repeat;
  font-size:1.7em;
  text-align:center;
  padding:10px;
  display:inline-flex;
  align-items: center;
}
.service-select .text { 
  border-left: 2px solid rgba(255,255,255,0.3);
  padding-left: 20px;
  width:100%;
}
a.service-select { color: inherit; text-decoration: none;}

.service-select {
  display:flex;
  float:left;
  max-width:400px;
  width:100%;
  margin-top:30px;
  margin:30px;
  padding:15px;
  background-color: #323345;
  background: linear-gradient(95deg, #323345, #494957);
}

@media only screen and (max-width: 767px) {
  .service-select {
    width: 90%;
    margin:10px auto;
  }
}

.service-select .title {
  font-size:1em;
  color:var(--bright-bg);
}
.service-select .description {
  font-size:0.8em;
  min-height:3em;
}
.top-bar {
  width:100%;
  font-size:14px;
  padding: 20px;
  background-color:#232430;
}
.top-bar .backButton{
  cursor:pointer;
  padding: 10px 30px 10px 10px;
  margin:0;
}
.top-bar .backButton  {
  border-right: 2px solid #57585c;
  margin-right:30px;
}
.top-bar .backButton svg {
  margin-right:10px;
}
.top-bar a {
  color:#fff;
}
.top-bar a:hover {
  color:#ccc;
}
.service-select:hover {
  background: linear-gradient(-95deg, #494957, #494957);

}
.service { 
  PADDING:20PX;
}

.titlebar {
  padding:10px;
}
.service .flex {
  display:flex; 
  justify-content:left;
}


.service .section{
  padding:20px;
}
.service .title {
  font-size:1.1em;
  color:var(--darker-text);
}


.divider { 
  height:1px;
  border-style: none none solid none;
  border-color:var(--bright-accent);
}
.select-condition .condition-right {
  width:70px;
  display:flex;
  align-items:center;
}
.select-condition .condition-right  svg {
  width:20px;
  height:20px;
  text-align:center;
}
.select-condition .condition-left .description {
  font-size:1.1em;
  padding: 0px 0px 10px 0px;
  color: var(--bright-bg);
}
.select-condition .condition-left .phone {
  font-size:.9em;
  margin-left:10px;
}
.select-condition .condition-left { 
  width:100%;
}
.select-condition .extra-data {
  margin-left:10px;
}
.select-condition .extra-data div {
  font-size:.8em;
  margin-right:20px;
  opacity:.7;
  
  float:left;
}
.select-condition > div  {
  display:flex;
}
.describe-condition > div  {
  display:flex;
}
.description {
  display:flex;
}
.description > div.wrap {
  margin-left:10px;
  width:200px;
  overflow:hidden;
  text-overflow:ellipsis;
}
.description > div.toggle {
  width:20px;
  overflow:hidden;
  text-overflow:ellipsis;
}
.select-condition .extra-data div svg { 
   margin-right:10px;
}
.select-condition {
  cursor:pointer;
  display:flex;
  justify-content:space-evenly;
  background-color: #fff;
  color:#14171f;
  padding:10px;
  width:100%;
  margin-top:2px;
  border-radius:10px;
  box-shadow:3px 0px 10px #eee;
  min-width:700px;
}
.describe-condition {
  display:flex;
  justify-content:space-evenly;
  color:#ccc;
  padding:10px;
  width:100%;
  margin-top:5px;
  border-radius:10px;
  min-width:700px;

}
.describe-condition>div{
  padding-left:10px;
  display:flex;

  width:100%;
}
.describe-condition svg {
  padding-top:5px;
  margin-right:10px;
  color:#ccc;
}
.select-condition svg {
  padding-top:5px;
  margin-right:10px;
  color:#ccc;
}
.select-condition>div{
  padding-left:10px;
  
  width:100%;
}
.select-condition:hover {
  background-color:#eaf3ff;
  box-shadow:3px 0px 10px #eee;
}

.addConditionButton {
  font-size:1em;
  font-family:Poppins;
  padding:0px;
  border:0px;
  text-transform: none;
  text-decoration: none;
  background-color:#16ac78;
  display:inline-block;
  margin: 20px 0px;
  padding:10px 20px;
  color:#fff;
  border-radius:5px;
}
.addConditionButton.active {
  background-color:#ccc;

}
.addConditionButton svg {
  margin-right:10px;
  
}
@media only screen and (max-width: 767px) {
  .conditions .used,.conditions .phone {
    display:none;
  }
  .describe-condition {
    min-width:unset;
    width:100%;
  }
  .select-condition {
    width:100%;
    min-width:unset;
  }
}

a { 
  text-transform: none;
  text-decoration: none;
}

.toggledOn {
  color:#16ac78 !important;
}
.toggledOff {
  color:#eb364e !important;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner {
  animation: spin 2s linear infinite;
  animation:
}

.service.caller  div.text-entry {
  padding-left:15px;
  color: var(--darker-text);
  margin-top:15px;
}

.service.caller  div.text-entry input {
  min-width:600px;
  padding:10px;
  border: 1px solid #ccc;
  margin:5px 0px;
}
.caller .isEnabled div {
  display:inline-block;
  cursor:pointer;
}

.caller .isEnabled div svg {
  width:40px;
  height:30px;
  color:#eb364e;
}
.caller .isEnabled div.active svg {
  color:#16ac78;
}
.caller label { 
  color:#0c1221;
}
.caller .text-entry {
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding-bottom:10px;
}
.caller .text-entry:nth-child(3){
  border:none;
}
.caller .save-div{
  padding:10px;
  padding-left:20px;
}
.caller .save-div .submit-save {
  padding:10px 30px;
  width:150px;
  background-color:#16ac78;
  border:none;
  color:#fff;
}
.caller .save-div .submit-save.active {
  background-color:#ccc
}
.caller .descriptive-text {
  padding-left:5px;
  font-size:.8em;
}
.caller .caller-flex {
  color: var(--dark-accent);
  display:flex;
  width:100%;
}
.caller .caller-flex .left {
  width:50%;
  min-width:600px;
}
.caller .caller-flex .right {
  width:50%;
  min-width:300px;
  background-color:#f1f5fb;
  padding:10px;
  margin-top:30px;
  min-height:300px;
  border-radius:10px;
}

.copy-email {
  color:var(--purple-2);
  border: 1px solid #ccc;
  display:inline-block;
  border-radius:3px;
  padding:3px 5px;
}
.received-email {
  margin-bottom:20px;
  padding:20px;
  background-color:#fff;
  border-radius:20px;

}
.received-email .entry {display:flex;}
.received-email .entry { width: 100%;padding:5px; border-bottom: 1px solid #ccc;}
.received-email .entry > div:first-child { width: 120px;}
.received-email .entry > div:last-child {
  color:#5449a4;
}
.caller-flex .descriptive {
  font-size:.8em;
}
hr {
border-top:none;

}
.error {
  color:#eb364e;
}
.email-entry {
  width:180px;
  max-width:180px;
  text-align:right;
}



/*
.rule {
  position:relative;
  margin-right:5px;
  margin:px;
  margin-left:20px;
  padding-left:10px;
  background-color:rgba(255, 241, 190, 0.1);
  padding:5px;
  border:2px solid rgba(0,0,0,.3);
}

:root {
  --padding: 25px;
  --margin: 0px;
}



.rule::before { 
  content:'';
  border-style: none none solid solid;
  height:  calc( 50%  + var(--margin) );
  left:0px;
  top: 0px;
  position:absolute;
}

.rule::after { 
  content:'';
  border-style: none none none solid;
  height:50%;
  left: 0px;  
  top: calc( 50% + var(--margin) ) ;
  position:absolute;
}

.rule::after,.rule::before {
  z-index:-0;
  border-color: #000;
  border-width:2px;
  left:-5px;
  margin-left:0px;
  left:-15px;
  height: calc(50% + 13PX) ;
  width: 12px;
  margin-top:-12px;
}

.rule { 
  position:relative;  
  text-align:left;
  padding:  var(--padding);
}

.rule:last-child::after { 
  content:'';
  border-style: none none none none;
  height:50%;
  left: 0px;  
  top: calc( 50% + var(--margin) ) ;
  position:absolute;
}

.type { 

}

*/

.rule {
}
.rule.group {
  border:2px dashed #b1b9c5;
}

.type {
  position:absolute;
  top:-22px;
  padding:10px;
  left:30px;
  background-color:#fcfcfc;
}
.ruleOptions {
  border: 1px solid #b1b9c5;
  display:flex;
  border-radius:5px;
  justify-content:start;
  background-color:#f1f5fb;
  padding:8px;
}
.ruleOptions > * {
  margin-right:10px;
}
.ruleOptions > * > *
{
  width:100%;
}
.ruleOptions > * 
{
  width:100%;
}
.ruleOptions > .small-width
{
  width:150px;
}
.ruleOPtions .large-width{
  width:100%;
}
.ruleOptions .delete {
  min-width:30px;
  width:30px;
  background:none;
  font-size:1.2em;;
  color:#666;
  border:none;
  margin-right:0px;
}
.ruleOptions .delete:hover {
  color:#eb364e;
}
.ruleset-wrapper {
  margin:10px;
  margin-top:15px;
  position:relative;
}
.ruleset-wrapper.group {
}
.rule.group{ 
  padding-top:10px;
}
.ruleset-wrapper.group { 
  margin-top:30px;
}


.rule .groupButtons {
padding:10px;}

.rule .groupButtons > * {
  margin-right:10px;
}

.loading-overlay {
  display:block;
  position:absolute;
  top:0;
  left:0; 
  width:100%;
  height:100%;
  background-color:rgba(255,255,255,0.4);
  text-align:center;
  padding-top:10px;
}

.checkflag {
  display:block;
  position:absolute;
  left:-7px; 
  top:-7px;
  width:20px;
  font-size:10pt;
  height:20px;
  text-align:center;
  border-radius:30px;
  line-height:20px;
  color:#fff;
}
.falseflag{
  background-color:#eb364e;
}


.trueflag{
  background-color:#16ac78;


}

.test-button {
  width:100%;
  padding:15px;
  border:none;
  background-color:#16ac78;
  color:#fff;
}
.test-button.active {
  background-color:#ccc;
}
.test-result {
  padding:10px;
  width:100%;
  margin-top:30px;
  text-align:center;
}

.test-result.fail {
  background-color:#fff;

  color:#eb364e
}
.test-result.success {
  color:#16ac78;
  background-color:#fff;

}
.chart-container {
  height:300px;
  width:400px;
}
.chart-container > canvas {
  height:300px;
  width:400px;
}





.callLog {
  height:250px;
}


.callLog table {
  width:calc(100% - 30px);
  font-size:.9em;
  border-spacing:0;
  border-collapse:collapse
}

.callLog table>*>*{
  padding-left:5px;
  border-bottom:1px solid rgba(0,0,0,0.1);
}
.callLog table td:first-of-type{
  color:rgba(0,0,0,0.8);
}
.callLog table td {
  color:rgba(0,0,0,0.6);
}

.callLog table tr:hover td {
  background-color:rgba(0,0,0,0.1);
}
.callLog table th {
  text-align:left;
  font-weight:normal;
  color:#0c1221;
}

@media only screen and (max-width: 767px) {
  .chart-container {
    height:300px;
    width:100%;
  }
  .chart-container > canvas {
    height:300px;
    width:100%;
  }
  .divider {
  float:left; width:90%;
  margin: 5px auto;
  }
  .service {
    width: calc(100% - 30px);
    box-sizing:border-box;
    padding: 5px;
  }
  .service .flex {
    display:block; 
    justify-content:left;
  }
  .service .section {
    float:left;
    padding:0;
    margin: 10px 0px;
  }
  .service * {
    box-sizing:border-box;
  }
  .callLog table>thead>*:nth-child(3),.callLog table>*>*:nth-child(2) {
    display:none;
  }
  .callLog table>tbody>tr>td:nth-child(3),.callLog table>tbody>tr>td:nth-child(2) {
    display:none;
  }
  .graphs .section {
    min-width:unset !important;
    width:100% !important;
  }
}



.pagination-navigation { 
display:flex;
justify-content:space-around;
}
.graphs .section:nth-child(1) {
  width:30%;
  min-width:500px;
}
.graphs .section:nth-child(2) {
  min-width:600px;
  width:70%;
}
.section {
  position:relative;
  min-height:300px;
}

.section .title {
  margin-bottom:30px;
}

.information {
  color:var(--card-bg-color);
  font-size:1em;
}
.inventory-flex {
  float:left;
}
.inventory-flex>div{ float:left; max-width:500px;margin:10px;}
table.inventory {
  border-collapse: collapse;
  width:100%;
  min-width:500px;
}

.threshold-td input {
  width:55px;
}
table.inventory.csv {
  max-width:400px;
  min-width:300px;
}
table.inventory tr td {
  padding: 5px;
  color:#000;
  text-align:center;
  border-bottom: 1px solid #ccc;
}
table.inventory tr td:first-child {
  text-align:left;
}
table.inventory tr:first-child td {
  padding: 10px;
}
table.inventory input {
  text-align:center;
}
.inventory tr td {
  position:relative;
}
.save-inventory {
  position:absolute;
  top:5px;
  right:10px;
  display:none;
}
.save-inventory.relative {
  position:relative;
  top:0px;
  right:0px;
} 
.save-inventory.show {
  display:inline-block;
}
.inventory-comment {
  font-size:.8em;
  color:var(--bright-text);
}

.inventory-comment.italic {
  font-style:italic;
}
.inventory-comment.small {
  font-size:.7em;
}
a {
  cursor:pointer;
}

.inventory-title {
  margin: 20px 0px;
  color: var(--darker-text);
  font-size:1.2em;
}
.save-inventory > * {
  color:#16ac78;
  animation: rotateSpinner 800ms linear infinite;
}
.save-inventory.gray > * {
  color:var(--card-bg-color);
}
.inventory-asset-box {
}
.inventory-asset-wrapper {
  display:flex;
  justify-content:space-around;
  flex-direction: row;
}
table.inventory tr:nth-child(odd) td {
  background-color:rgba(0,0,0,.05);
}
@media only screen and (max-width: 767px) { 
  table.inventory {
    width:100%;
    min-width:unset;
  }
}

td.warn{
  color:#d83939 !important;
}

.edit-key * {
  pointer-events:none;
  padding-left:10px;
}

#menu-toggle { 
  display:none;
}
.hamburger-toggle {
  position:absolute;
  height:50px;
  width:40px;
  margin-left:5px;
  line-height:50px;
}
.hamburger-toggle svg {
  width:100%;
}

@media only screen and (max-width: 767px) {
  input {
    min-width: unset !important;
  }
  .main {
    margin-top:50px;
  }
  #menu-toggle { 
    display:block;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:50px;
    background-color:#232430;
  } 
  .callLog { height:unset;}
  .top-bar .backButton {  margin-right:3px; padding:0; font-size:0px;}
  .top-bar .backButton svg {  font-size:14px;}
  
}

@keyframes rotateSpinner {
	to {
		transform: rotate(360deg);
	}
}

#root,html,body {
  width:100%;min-height:100%;
}

.hidden { 
  display:none !important;
}